/* You can add global styles to this file, and also import other style files */

@import "ngx-toastr/toastr";



html {
    zoom: 95%;
}

// ngx-toastr
.toast-container .ngx-toastr {
    font-size: 18px;
    width: 400px;
}

/** Sidebar **/
// Sidebar ico
.sidebar-nav > ul > li > a i {
    width: 31px;
    font-size: 17px;
} 

// foto
.img-avatar {
    height: 150px;
}

// Cursores
.pointer {
    cursor: pointer;
}
.arrow {
    cursor: context-menu;
}

// Fuente
.italic {
    font-style: italic;
}

.small {
    font-size: small;
}

.grande {
    font-size: 2rem;
}

.monospace {
    font-family: "Verdana", monospace;
    letter-spacing: 0.6px;
}

// width
.w100 {
    width: 100px;
}

// márgenes
.mt-12 {
    margin-top: 12px;
}
.mt-30 {
    margin-top: 30px;
}

//  margin-left
.ml-2 {
    margin-left: 2px;
}
.ml-35 {
    margin-left: 352px;
}

//  margin-right
.mr-12 {
    margin-right: 12px;
}
.mr-20 {
    margin-right: 20px;
}
.mr-42 {
    margin-right: 42px;
}

// padding
.pl-25 {
    padding-left: 25px;
}
.pt-29 {
    padding-top: 29px;
}

/* Colores */

.tomato {
    color: tomato;
}

.bg-pale-red {
    background-color: #ffc6c6; /* Color rojo pálido */
}
